import React from "react"
import "./comparison.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"



const Comparison = ({ title, step1, step2, step3, image1, image2, image3, topPadding, bottomPadding, displayNumbers }) => {

    return (
        <div className={` ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''} lateral-padding comparison-container`}>
            <div className="how-works ">

                <div uk-scrollspy="cls: uk-animation-slide-bottom; target: .how-works__card-title; delay: 500; repeat: true">
                    <h2 className="how-works__card-title">
                        {/* {[...title].map((char, index) => (
                        <span key={index} className="letter">{char === ' ' ? '\u00A0' : char}</span>
                    ))} */}
                        {title}
                    </h2>
                </div>

                <div className="how-works__container uk-child-width-1-3@s uk-grid-small uk-margin-remove uk-text-center" data-uk-grid uk-scrollspy="cls: uk-animation-fade; target: .how-works__container_item; delay: 500; repeat: true">
                    <div className={displayNumbers ? "how-works__container_item" : "how-works__container_item center-alignment"}>

                        {image1 ? <GatsbyImage image={image1.gatsbyImageData} alt="How Comparison works - Step 1" className="item-image" objectFit="contain"></GatsbyImage> : ""}
                        <div className="item-description uk-grid uk-grid-collapse">
                            {displayNumbers ? <div className="item-description__icon uk-width-1-6">1</div> : ""}
                            <div className="item-description__text uk-width-5-6"><CustomMarkdown>{step1}</CustomMarkdown></div>
                        </div>

                    </div>
                    <div className={displayNumbers ? "how-works__container_item" : "how-works__container_item center-alignment"}>

                        {image2 ? <GatsbyImage image={image2.gatsbyImageData} alt="How Comparison works - Step 2" className="item-image" objectFit="contain"></GatsbyImage> : ""}
                        <div className="item-description uk-grid uk-grid-collapse">
                            {displayNumbers ? <div className="item-description__icon uk-width-1-6">2</div> : ""}
                            <div className="item-description__text uk-width-5-6"><CustomMarkdown>{step2}</CustomMarkdown></div>
                        </div>

                    </div>
                    <div className={displayNumbers ? "how-works__container_item" : "how-works__container_item center-alignment"}>

                        {image3 ? <GatsbyImage image={image3.gatsbyImageData} alt="How Comparison works - Step 3" className="item-image" objectFit="contain"></GatsbyImage> : ""}
                        <div className="item-description uk-grid uk-grid-collapse">
                            {displayNumbers ? <div className="item-description__icon uk-width-1-6">3</div> : ""}
                            <div className="item-description__text uk-width-5-6"><CustomMarkdown>{step3}</CustomMarkdown></div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )

}

export default Comparison